.languageNavigation {


  a.nav-link.item {
	color: grey;
  }

  span.nav-link.divider:hover {
	cursor: context-menu;
  }

  a.nav-link.item:hover {
	color: grey;
	cursor: pointer;
  }

  .nav-link {

	&.divider {
	  padding: 0;
	}

	display: inline;
	font-size: 1rem;
	line-height: 2.5rem;
	padding-right: .2rem;
	padding-left: .2rem;
  }
  &:hover {
	cursor: context-menu;
  }
}

.main-nav {
  .nav-item {
	display: inline-block;
  }
}
@keyframes scrollbounce {
  50% {
	transform:
			scale(.75)
			translateY(-5%);
  }
  100% {
	transform: translateY(0);
  }
}
.scroll-me {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 4rem;
  transform: translateX(-50%);
  z-index: 10;
  height: 60px;
  line-height: 1;
  .fal {
	position: relative;
	vertical-align: bottom;
	animation: scrollbounce 2s ease infinite;
  }
  &:before {
	position: absolute;
	bottom: 0;
	content: "";
	width: 0;
	height: 0;
	border-left: 81px solid transparent;
	border-right: 81px solid transparent;
	border-bottom: 64px solid #F2F2F2;
	transform: translateX(-66px);
	z-index: 0;
  }
}
.tile {
  margin-bottom: $grid-gutter-width;
  transition: all 0.25s ease;
  a:hover {
	  text-decoration: none;
  }
  .tilein {
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
	padding-bottom: 32px;
  }
  .squares {
	opacity: 0;
  }
  &:hover {
	.squares {
	  opacity: 1;
	}
	.tilein {
	  color: $dark !important;
	  background-color: $white !important;
	  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
	}
  }
}

.py-6 {
  padding-top: 4.95rem;
  padding-bottom: 4.95rem;
}

.nbar {
  transition: all 0.3s ease-in-out;
  &.hideme {
	top: -100%;
	opacity: 0;
  }
}

#maninav {
  position: fixed;
  top:0;
  width: 100%;
  max-width: 100%;
  z-index: 1029;
  &.show {
	//height: 100vh;
	//height: calc(100vh - env(viewport-nav-resize));
  height: calc(var(--vh, 1vh) * 100) !important;
	overflow-x: hidden;
	overflow-y: auto;
  }
  .mobile {
	padding-top: 6rem;
	padding-bottom: 2rem;
	max-height: calc(100vh - 60px);
	flex-wrap: nowrap;
  }
  &:before {
	content: "";
	position: fixed;
	background: $white;
	opacity: 0.9;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
    z-index: -1;
  }
}

.mainnav.mobile {
  .subtrigger {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 44px;
    line-height: 44px;
    top: 0;
    right: 10px;
    text-align: center;
  }
}
.mainnav.mobile .collapsed.fal {
  &:before {
	content: "";
  }
}

#mobilenav .nav-link {
  position: relative;
  padding-right: 60px;
}
