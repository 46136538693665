

.downloadlist {

  a {
    &:hover {
      text-decoration: none;

      .itemin {
        background-color: $download-item-bg-hover;
      }
    }
  }

  .item {
    margin-bottom: 10px;
  }
  .itemin {
      background-color: $download-item-bg;
      color: $download-item-color;

    .itemin-in {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .icon-container {
    margin: auto 0;

    i.fal {
      font-size: 1.9rem;
      font-weight: lighter;
      padding: 10px;
    }
  }

  .item-title {
    font-size: 2.4rem;
    margin: auto 0;
    width: calc(100% - 40px);
    padding-right: 10px;
  }
}

.downloadtiles {
  padding-right: 6px;
  padding-left: 6px;



  .item {
    padding-right: 4px;
    padding-left: 4px;
  }

  a {
    &:hover {
      text-decoration: none;

      .title-label {
        color: $download-tile-hover;
      }
    }
    .title-label {
      font-size: 2.4rem;
      color: $download-tile-color;
    }
    img.downloadtile {
      height: calc(68vw);
      filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.2));
    }
  }

}

@include media-breakpoint-up(sm) {
  .downloadlist {

    .item {
      margin-bottom: 20px;
    }

    .itemin-in{
      padding: 20px 0;
    }
    .icon-container {

        i.fal {
          font-size: 2.3rem;
          font-weight: lighter;
          padding: 0 50px 0 20px;
         }
      }

      .item-title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        width: calc(100% - 100px);
      }
  }

  .downloadtiles {

    a {
      .title-label {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-top: 5px;
      }
    }

  }
}

@include media-breakpoint-up(md) {

  .downloadtiles {
    a {
      .itemin {
        padding-left: 5px;
        padding-right: 5px;

        img.downloadtile {
          height: calc(34vw);
        }
      }
    }
  }

}


@include media-breakpoint-up(lg) {

  .downloadtiles {
    a {
      .itemin {
        padding-left: 7px;
        padding-right: 7px;

        img.downloadtile {
          height: 382px;
        }
      }
    }
  }
}
