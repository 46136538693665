.main-footer {
  .nav-link {
    padding: 0;
    color:$dark;
    text-decoration: underline;
	/*font-family: "Open Sans Condensed";
	margin-bottom: 1rem;
	&:hover {
	  text-decoration: underline;
	}*/
  }
  .collapsed .fal {
	&:before {
	  content: "";
	}
  }
}

.social-menu {
  li {
    position: relative;
    display: block;
    background: transparent;
    border: 0;
    padding: 0 0.75rem 0 0 !important;
    a {
      color: $gray-900;
      &:hover {
        color: $red;
      }
    }
  }
}
