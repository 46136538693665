.products-box a:hover h4 {
  color: $red;
  text-decoration: none;
}

.products-box a:hover {
  cursor: pointer;
}

.products-box .swiper-button-prev,
.products-box .swiper-button-next {
  top: 120px;
  color: $gray-900;
}

.products-box .swiper-button-prev {
  left: -70px;
}

.products-box .swiper-button-next {
  right: -70px;
}

.products-box .swiper-wrapper {
  justify-content: center;
  padding-left: 10px;
}

.products-box .products-box-active .swiper-wrapper {
	justify-content: flex-start;
  	padding-left: 0;
}

.tx-axnumproducts-products .product-list .filter .label {
  font-family: Open Sans;
}

@include media-breakpoint-down(lg) {
  .products-box .swiper-button-prev {
	left: -30px;
  }

  .products-box .swiper-button-next {
	right: -30px;
  }
}

@include media-breakpoint-down(md) {
  .products-box .swiper-button-prev {
	left: -10px;
  }

  .products-box .swiper-button-next {
	right: -10px;
  }
}


@include media-breakpoint-down(sm) {
  .products-box .swiper-button-prev,
  .products-box .swiper-button-next {
	top: 45%;
  }
}