/*!
 * @license MIT
 * @name vlitejs
 * @version 4.0.4
 * @copyright 2021 Yoriiis aka Joris DANIEL
 */
.v-vlite {
  --vlite-colorPrimary: $dark;
  --vlite-transition: 0.25s ease;
  --vlite-controlBarHeight: 50px;
  --vlite-controlBarHorizontalPadding: 10px;
  --vlite-controlBarBackground: linear-gradient(0deg, #000 -50%, transparent);
  --vlite-controlsColor: #fff;
  --vlite-controlsOpacity: 0.9;
  --vlite-controlsIconWidth: 12px;
  --vlite-controlsIconHeight: 12px;
  --vlite-progressBarHeight: 5px;
  --vlite-progressBarBackground: rgba(0, 0, 0, 25%);
  font-family: -apple-system, blinkmacsystemfont, segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  overflow: hidden;
  position: relative
}

.v-vlite, .v-vlite * {
  box-sizing: border-box
}

.v-vlite.v-audio {
  --vlite-controlsColor: #000;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 10%)
}

.v-vlite.v-video {
  background-color: #000;
  height: 0;
  padding-top: 56.25%
}

.embed-responsive .v-vlite.v-video {
  padding-top: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.v-vlite::-webkit-full-screen {
  height: 100%;
  width: 100%
}

.v-vlite::-moz-full-screen, ::-moz-full-screen .v-vlite {
  height: 100%;
  width: 100%
}

.v-vlite video::-webkit-media-controls {
  display: none !important
}

.v-vlite.v-fullscreenButton-display .v-bigPlay, .v-vlite.v-fullscreenButton-display .v-controlBar {
  position: fixed;
  z-index: 2147483647
}

.v-vlite.v-paused .v-controlBar.hidden {
  opacity: 1
}

.v-vlite.v-paused:not(.v-firstStart) .v-poster, .v-vlite.v-playing .v-poster {
  opacity: 0;
  visibility: hidden
}

.v-vlite.v-playing .v-bigPlay {
  opacity: 0;
  visibility: hidden
}

.v-vlite iframe.vlite-js {
  pointer-events: none
}

.herovideo, .v-vlite .vlite-js, .v-vlite .vlite-js > iframe {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.v-controlButton {
  -webkit-appearance: none;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  justify-content: center;
  opacity: var(--vlite-controlsOpacity);
  outline: none;
  padding: 0;
  position: relative;
  transition: opacity var(--vlite-transition);
  width: 40px
}

.v-controlButton * {
  pointer-events: none
}

.v-controlButton:hover {
  opacity: 1
}

.v-controlButton.v-controlPressed .v-iconUnpressed, .v-controlButton:not(.v-controlPressed) .v-iconPressed {
  display: none
}

.v-controlButton.focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: -4px
}

.v-controlButton:focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: -4px
}

.v-controlButton svg {
  fill: var(--vlite-controlsColor);
  height: var(--vlite-controlsIconHeight);
  width: var(--vlite-controlsIconWidth)
}

@keyframes loader {
  0%, 80%, to {
    transform: scale(0)
  }
  40% {
    transform: scale(1)
  }
}

.v-loader {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity var(--vlite-transition), visibility var(--vlite-transition);
  visibility: hidden;
  width: 100%;
  z-index: 5
}

.v-loaderContent {
  margin: 0 auto;
  text-align: center;
  width: 70px
}

.v-loaderContent > div {
  animation: loader 1.4s ease-in-out infinite both;
  background-color: var(--vlite-controlsColor);
  border-radius: 100%;
  display: inline-block;
  height: 18px;
  width: 18px
}

.v-loaderContent .v-loaderBounce1 {
  animation-delay: -.32s
}

.v-loaderContent .v-loaderBounce2 {
  animation-delay: -.16s
}

.v-loading .v-loader {
  opacity: 1;
  visibility: visible
}

.v-controlButtonIcon {
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%)
}

.v-playPauseButton {
  flex-shrink: 0
}

.v-time {
  align-items: center;
  color: var(--vlite-controlsColor);
  display: flex;
  font-size: 14px;
  height: 100%;
  margin-right: auto
}

.v-progressBar {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  touch-action: manipulation;
  transition: height var(--vlite-transition), top var(--vlite-transition);
  user-select: none;
  -webkit-user-select: none;
  width: calc(100% - var(--vlite-controlBarHorizontalPadding) * 2);
  z-index: 1
}

.v-progressBar.focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: 2px
}

.v-progressBar:focus-visible {
  outline: 2px dashed var(--vlite-colorPrimary);
  outline-offset: 2px
}

.v-progressBar::-webkit-slider-runnable-track {
  background: linear-gradient(to right, var(--vlite-colorPrimary) var(--value, 0), var(--vlite-progressBarBackground) var(--value, 0));
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  height: var(--vlite-progressBarHeight)
}

.v-progressBar::-moz-range-track {
  background: linear-gradient(to right, var(--vlite-colorPrimary) var(--value, 0), var(--vlite-progressBarBackground) var(--value, 0));
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  height: 100%
}

.v-progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--vlite-colorPrimary);
  border: none;
  border-radius: 50%;
  height: 12px;
  margin-top: -3px;
  width: 12px
}

.v-progressBar::-moz-range-thumb {
  background: var(--vlite-colorPrimary);
  border: none;
  border-radius: 50%;
  height: 12px;
  margin-top: -3px;
  width: 12px
}

.v-volumeButton {
  flex-shrink: 0;
  transition: transform var(--vlite-transition), opacity var(--vlite-transition)
}

.v-volumeButton.v-animate {
  transform: translateY(-3px)
}

.v-controlBar {
  align-items: center;
  display: flex;
  height: var(--vlite-controlBarHeight);
  justify-content: flex-end;
  padding-left: var(--vlite-controlBarHorizontalPadding);
  padding-right: var(--vlite-controlBarHorizontalPadding);
  padding-top: var(--vlite-progressBarHeight);
  position: relative;
  text-align: right;
  transition: opacity var(--vlite-transition);
  width: 100%
}

.v-controlBar:hover {
  opacity: 1 !important
}

.v-controlBar.hidden {
  opacity: 0
}

.v-audio .v-progressBar {
  height: 5px;
  order: 2
}

.v-audio .v-controlBar {
  padding-top: 0
}

.v-audio .v-playPauseButton {
  order: 1
}

.v-audio .v-time {
  order: 2;
  padding: 0 8px 0 12px
}

.v-audio .v-volumeButton {
  order: 4
}

.v-video .v-progressBar {
  height: 5px
}

.v-video .v-controlBar {
  background: var(--vlite-controlBarBackground);
  bottom: 0;
  left: 0;
  position: absolute
}

.v-video .v-controlBar .v-progressBar {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%)
}

.v-bigPlay {
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 75px;
  z-index: 2
}

.v-bigPlay, .v-bigPlay svg {
  transition: opacity var(--vlite-transition)
}

.v-bigPlay svg {
  fill: var(--vlite-controlsColor);
  height: 100%;
  opacity: var(--vlite-controlsOpacity);
  width: 100%
}

.v-bigPlay:hover svg {
  opacity: 1
}

.v-loading .v-bigPlay {
  opacity: 0
}

.v-overlay {
  height: calc(100% - var(--vlite-controlBarHeight))
}

.v-overlay, .v-poster {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.v-poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 2
}

.v-poster.v-active {
  opacity: 1;
  visibility: visible
}

.v-loader,
.vimeo .v-video .v-bigPlay,
.youtube .v-video .v-bigPlay,
.vimeo .v-video .v-controlBar,
.youtube .v-video .v-controlBar {
  opacity: 0 !important;
  visibility: hidden;
}

.vimeo .v-vlite .v-poster + .v-bigPlay,
.youtube .v-vlite .v-poster + .v-bigPlay {
  opacity: 1 !important;
  visibility: visible;
}

.vimeo .v-vlite.v-playing .v-poster + .v-bigPlay,
.youtube .v-vlite.v-playing .v-poster + .v-bigPlay {
  opacity: 0 !important;
  visibility: hidden;
}

.vimeo .v-vlite iframe.vlite-js,
.youtube .v-vlite iframe.vlite-js {
  pointer-events: all;
}

.v-video .v-controlBar .v-progressBar {
  border-radius: 5px;
  background-color: rgba($red, 0.5);
  margin: 0 !important;
}

.v-progressBar::-webkit-slider-runnable-track {
  height: var(--vlite-progressBarHeight);
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  background: linear-gradient(
      to right,
      var(--vlite-colorPrimary) var(--vlite-progressValue, 0),
      var(--vlite-progressBarBackground) var(--vlite-progressValue, 0)
  );
}

.v-progressBar::-webkit-slider-thumb {
  appearance: none;
  width: calc(var(--vlite-progressBarHeight) * 3);
  height: calc(var(--vlite-progressBarHeight) * 3);
  border-radius: 50%;
  border: none;
  background: var(--vlite-colorPrimary);
  margin-top: calc((var(--vlite-progressBarHeight)) * -1);
}

.v-progressBar::-moz-range-track {
  height: 100%;
  border: none;
  border-radius: var(--vlite-progressBarHeight);
  background: linear-gradient(
      to right,
      var(--vlite-colorPrimary) var(--vlite-progressValue, 0),
      var(--vlite-progressBarBackground) var(--vlite-progressValue, 0)
  );
}

.v-progressBar::-moz-range-thumb {
  width: calc(var(--vlite-progressBarHeight) * 3);
  height: calc(var(--vlite-progressBarHeight) * 3);
  border-radius: 50%;
  border: none;
  background: var(--vlite-colorPrimary);
  margin-top: calc((var(--vlite-progressBarHeight)) * -1);
}

.v-volumeArea .v-iconUnpressed {
  width: 24px !important;
  height: 22px !important;

  @include media-breakpoint-down(sm) {
    width: 18px !important;
    height: 16px !important;
  }
}

.v-volumeArea .v-iconPressed {
  width: 24px !important;
  height: 22px !important;

  @include media-breakpoint-down(sm) {
    width: 18px !important;
    height: 16px !important;
  }
}

.v-bigPlay svg {
  display: none;
}

.v-bigPlay {
  background: $red !important;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:before {
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-size: 28px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    content: "";
    background-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='18' width='12' viewBox='0 0 12 18'><polygon points='0 18 0 0 12 9' fill='$dark'></polygon></svg>"));
    color: $dark;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 18px;
    position: absolute;
  }

  background: $white;
  border-radius: 50%;
}

.v-overlay {
  /*background: rgba($black,0);*/
  height: inherit;
  //z-index: 3;
  bottom: 20%;
}

.v-paused {
  .v-overlay {
    bottom: 0;
    height: auto;
    /*background: rgba($black,.56);*/
  }
}

.v-vlite.v-audio {
  border-radius: 38px;
  box-shadow: none;
  position: absolute;
  overflow: visible;
  bottom: 30px;
  left: 30px;
  right: 30px;
  --vlite-controlsColor: $dark;
  z-index: 100;
  background: rgba($white, .75);
  backdrop-filter: blur(10px);

  @include media-breakpoint-down(sm) {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }

  @include media-breakpoint-down(xs) {
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

  .tspacer {
    display: none;
  }

  .v-time {
    color: $body-color;
  }

  .v-controlBar {
    --vlite-controlBarHorizontalPadding: 15px;
    --vlite-controlBarHeight: 75px;
  }

  .v-fullscreenButton {
    display: none;
  }
}

.v-audio .v-time {
  padding: 0px 0px 0px 12px;
  margin-left: 12px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.v-audio .v-progressBar {
  height: 5px;
}

.podcast .v-time.audio {
  order: 3;
}

.podcast .v-time.main {
  order: 1;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.underlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.alternative-content {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  .overlay {
    backdrop-filter: blur(6px);
    background-color: rgba(255,255,255, 0.4);
  }

  img {
    filter: blur(6px);
  }
}

.embed-responsive-100 {
  .v-vlite.v-video {
    padding-top: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .v-container {
      &:before {
        padding-top: 100vh;
      }
    }
    .video-frame {

    }
  }
  .v-controlBar {
    opacity: 1;
    visibility: hidden;
  }
}
