form {
  &.tx-form {
    color: $black;
  }
  .form-group {
    .form-control {

    }
    .custom-control-input:checked~.custom-control-label::before {
      color: #fff;
      border-color: $black;
      background-color: $black;
    }
    select.custom-select {
      background: #fff url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat right .75rem center;
      option{
        &:checked {
          background: $black;
          color: $white;
        }
        background: $white;
        color: $gray-900;
      }
      &.dark {
        background: $gray-900 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat right .75rem center;
        color: $white;
      }
    }
    .custom-control {
      margin-bottom: .5rem;
    }
  }
  .form-control.is-invalid,.was-validated .form-control:invalid {
    background-image: none;
  }
}

.product-list {
  .bootstrap-select {
    width: 100% !important;
    button {
      color: $white;
      background: $gray-900 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat right .75rem center;
      border-color: $gray-900;
      border-radius: 0;
      font-family: Open Sans Condensed;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 34px;
      &:after {
        content: none;
      }
      &:hover {
        color: $white;

      }
      &.dropdown-toggle {
        &:focus {
          outline: 0 !important;
          box-shadow: none;
        }
        &.bs-placeholder,
        &.bs-placeholder:hover,
        &.bs-placeholder:focus,
        &.bs-placeholder:active {
          color: $white;
        }
      }
      &:focus {
        color: $white;
        background: $gray-900 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat right .75rem center;
        border-color: $gray-900;
      }
    }
    &.show {
      button {
        background: $gray-900 url("data:image/svg+xml;utf8,<svg viewBox='0 0 448 512' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z' fill='white'/></g></svg>") no-repeat right .75rem center;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
      padding: 0;
      margin: 0;
      .dropdown-item {
        &.active,
        &:active {
          color: #fff;
          border-color: $black;
          background-color: $black;
        }
        &:focus,
        &:hover {
          color: $red;
          border-color: $white;
          background-color: $white;
        }
      }
    }
  }
}
