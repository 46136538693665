:root {
  --swiper-theme-color: $white;
  --swiper-navigation-size: 36px;
}

//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }

//@include gradient-radial($white,$gray-300);
//@include gradient-y($white,$gray-900,55.3%,100%);

// this is the place for your components / CSS
/* Typo3 Spaces */
.frame-space-before-extra-small { margin-top: 0.3125rem !important; }
.frame-space-before-small { margin-top: 1.5625rem !important; }
.frame-space-before-medium { margin-top: 3.125rem !important; }
.frame-space-before-large { margin-top: 4.6875rem !important; }
.frame-space-before-extra-large { margin-top: 6.25rem !important; }
.frame-space-after-extra-small { margin-bottom: 0.3125rem !important; }
.frame-space-after-small { margin-bottom: 1.5625rem !important; }
.frame-space-after-medium { margin-bottom: 3.125rem !important; }
.frame-space-after-large { margin-bottom: 4.6875rem !important; }
.frame-space-after-extra-large { margin-bottom: 6.25rem !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { margin-top: 0.3125rem !important; }
  .frame-space-before-small { margin-top: 1.5625rem !important; }
  .frame-space-before-medium { margin-top: 3.125rem !important; }
  .frame-space-before-large { margin-top: 4.6875rem !important; }
  .frame-space-before-extra-large { margin-top: 6.25rem !important; }
  .frame-space-after-extra-small { margin-bottom: 0.3125rem !important; }
  .frame-space-after-small { margin-bottom: 1.5625rem !important; }
  .frame-space-after-medium { margin-bottom: 3.125rem !important; }
  .frame-space-after-large { margin-bottom: 4.6875rem !important; }
  .frame-space-after-extra-large { margin-bottom: 6.25rem !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { margin-top: 0.3125rem !important; }
  .frame-space-before-small { margin-top: 1.5625rem !important; }
  .frame-space-before-medium { margin-top: 3.125rem !important; }
  .frame-space-before-large { margin-top: 4.6875rem !important; }
  .frame-space-before-extra-large { margin-top: 6.25rem !important; }
  .frame-space-after-extra-small { margin-bottom: 0.3125rem !important; }
  .frame-space-after-small { margin-bottom: 1.5625rem !important; }
  .frame-space-after-medium { margin-bottom: 3.125rem !important; }
  .frame-space-after-large { margin-bottom: 4.6875rem !important; }
  .frame-space-after-extra-large { margin-bottom: 6.25rem !important; }
}

body {
  padding-top: 90px;
}

// content wrapper
.content-wrapper { padding: $content-wrapper-padding 0; }
.element-wrapper { margin-bottom: $spacer*2}
.main-header,
.main-container,
.main-footer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.main-container {
  max-width: 1160px;
}

.main-footer {
  min-height: 287px;
  padding: 0;
  background: $gray-300;
  padding: 1.5rem 0 0.5rem 0;
}

.navigation-wrap {
  padding-right: 32px;
  padding-left: 32px;
}

.logo {
  max-width: 205px;
}

.container-wrap {
  max-width: 1024px;
  margin: 0 auto;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5 {
  font-family: "Open Sans Condensed";
}


/*faultmessages*/
$redlight: #F2E5E5;
$bluelight: #ECF2FB;
$messageshadow: 0 2px 4px rgba($black, .1) !default;
$slidershadow: 0px 2px  4px rgba($black,.3) !default;
$arrowhadow: 0px -10px 10px rgba(0, 0, 0, 0.17) !default;
$evetnhovershadow: 5px 5px 20px rgba(0, 0, 0, 0.2) !default;

.message-tittle {
  font-size: $h1-font-size;
}
.message-box {
  padding:$spacer;
  border-left: 10px solid $red;
  background: $white;
  @include box-shadow($messageshadow);
}
.message{
  border: 1px solid $red;
  margin-bottom: $grid-gutter-width;
  .icon {
    width: 80px;
    color: $white;
    text-align: center;
    font-size: 3rem;
    padding-top: 1rem;
  }
  .text {
    padding: .5rem 1rem;
    p {
      font-family: "Open Sans Bold";
    }
  }
  &.primary{
    border-color: $red;
    .icon {
      background: $red;
    }
    .text {
      color: $red;
      background: $redlight;
    }
  }
  &.secondary{
    border-color: $secondary;
    .icon {
      background: $secondary;
    }
    .text {
      color: $secondary;
      background: $bluelight;
    }
  }
}

/* keyvisual */
.box-slide-wrapper {
  margin: 0 auto;
  max-width: 574px;
  padding: 20px 82px;
  transform: translateY(-50%);
  .swiper-controls button {
    transform: translateY(-50%);
    width: 82px;
    height: 82px;
    @include box-shadow($slidershadow);
    text-align: center;
    color: $red;
    background-size: auto;
    background-repeat: no-repeat;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    outline: none;
    margin: 0;
    &.slide-prev {
      left:0;
      @include gradient-x($white, $gray-500);
    }
    &.slide-next {
      right:0;
      @include gradient-x($gray-500, $white);
    }
  }

  .boxes-slider {
    .swiper-wrapper {
      margin-left: -25px;
    }
    .slide-icon-box {
      width: 120px;
      height: 120px;
      background: $red;
      @include gradient-x($red, $reddark);
      @include box-shadow($slidershadow);
      color: $white;
      padding: 1rem 0.5rem 0.5rem;
      margin: 0 auto;
    }
    .swiper-slide-next .slide-icon-box {
      @include gradient-x($reddark,$red);
    }
    .swiper-slide {
      width: 120px;
    }
    .swiper-slide-active {
      width: 174px;
      @include box-shadow($slidershadow);
      .slide-icon-box {
        width: 174px;
        height: 174px;
        background-image: none;
        background: $red;
        padding: 1rem;
        div {
          transform: scale(1.45);
        }
        .fas {
          line-height: inherit;
        }
      }
    }
  }
}

/*iconboxes*/
.icon-box {
  background: $white;
  @include box-shadow($messageshadow);
  margin-bottom: $grid-gutter-width;
  .icon {
    width: 80px;
    color: $white;
    text-align: center;
    font-size: 3rem;
    padding: 1rem 0;
    background: $secondary;
  }
  .text {
    padding: .5rem 1rem;
    &.with-image {
      position: absolute;
      top: 0;
      left:0;
      bottom: 0;
      color: $white;
    }
  }
  .embed-responsive {
    .focus-wrap {
      position: absolute;
      top:  0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before{
      padding-top: 56%;
    }
  }

  .icobox-link {
    font-family: "Open Sans SemiBoldItalic";
    i {
      margin-left: .5rem;
    }
  }
  .date {
    font-family: "Open Sans Bold";
  }
  .inside-box {
    background: $light;
    border: 5px solid $gray-400;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .box-item {
    border:1px solid $red;
    border-left-width: 10px;
    padding:.5rem;
    margin-bottom: .5rem;
  }
}

.lead-text {
  font-size: 1.625rem;
  font-family: "Open Sans Condensed";
  width: 70%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.w-70{
  width: 70%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.new {
  color: $red;
  position: absolute;
  top: 20px;
  left: 20px;
  transition: all 0.25s ease;
}

.productteaser .card-img-overlay {
  background: url('#{$image-path}grad.png') bottom left repeat-x;
  color: $white;
  transition: all 0.25s ease;
  &:hover {
    background: transparent;
    color: transparent;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    .new {
      transform: scale(1.4);
      font-weight: 700;
    }
  }
}

.item-in {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.events .card {
  border-radius: 0;
  margin-bottom: $grid-gutter-width;
  .card-img-top {
    min-height: 100%;
    width: auto;
    min-width: 100%;
  }
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}
.events a {
  &:hover {
    text-decoration: none;
    .card {
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  left: auto;
  right: 5% !important;
  width: auto;
}
.swiper-pagination-bullet {
  border-radius: 0;
  width: 12px;
  height: 12px;
  background: none;
  opacity: 1;
  border: 2px solid $white;
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,.2);
}
.swiper-pagination-bullet-active {
  background: $white;
}

.submenu {
  background: radial-gradient(83.47% 1579.83% at 50% 50.15%, #FFFFFF 0%, #D9D8D7 100%);
  box-shadow: 1px 1px 20px 5px rgba(0, 0, 0, 0.17);
  transition: all 0.05s ease;
  &.show {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: -50px;
      z-index: -1;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}
.nav-item .squares {
  opacity: 0;
  position: absolute;
  left: calc(50% - 2px);
  bottom: 6px;
  height: 4px;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: left;
  font-family: "Open Sans Condensed";
}
.mainnav .nav-link {
  font-family: "Open Sans Condensed";
  font-size: 1.625rem;
  padding: 0.4rem 2rem;
}
.mainnav.desktop  .nav-link {
  color: $white;
}
.submenu .level-1 {
  min-height: 90px;
}
.mynavbar {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.navbar-dark .navbar-toggler {
  border: none;
  color: $white;
}
.hamburger-box {
  width: 32px;
}
.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
  background-color: $white;
  width: 32px;
  height: 2px;
  border-radius: 2px;
}

.menu-overlay {
  background: rgba(47, 50, 52, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 80px;
  display: none;
  z-index: 999;
  &.show {
	display: block;
  }
}
.mainnav.desktop > .nav-item {
  transition: all 0.25s ease;
  position: relative;
}

.mainnav.desktop > .nav-item:hover,
.desktop .nav-item.active {
  background: $white;
  color: $dark;
  .squares {
    opacity: 1;
  }
  .hamburger {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: $black;
    }
  }
}

.navbar-dark .navbar-nav .nav-item .nav-link:hover,
.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: $dark;
}
.navbar-nav {
  .hamburger {
    padding: .4rem .5rem;
    font-size: 1.625rem;
    &:hover {
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: $black;
      }
    }
  }
}
.breadcrumb,
.metanav .nav-link {
  font-family: "Open Sans Condensed";
}

.metanav .nav-link {
  &:hover {
    text-decoration: underline;
  }
}

.langmenu .nav-link {
  text-transform: uppercase;
}
.breadcrumb {
  font-size: 12px;
}
.breadcrumb-item::before {
  display: inline-block;
  padding-right: .25rem;
  color: #646567;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: .95rem;
  line-height: 1.15rem;
}
.blog-columns,
.news-columns {
  .card {
    margin-bottom: 1.875rem;
    .item-in {
      @media only screen and (device-width: 768px) {
      /* #89321 hidden shadow for ios devices, news dissapears */
        box-shadow: none !important;
      }
      &:hover {
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
        a {
          color: $red !important;
          text-decoration: none !important;
        }
      }

      a {
        &:hover {
          h4 {
            color: $red !important;
          }
        }
      }
    }

  }
}
.blog-compact,
.newstips-compact {
  .card-compact {

    .card-body-compact {
      background-color: $list-group-bg;
    }

    background-color: $list-group-bg;
  }
  a:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
  }
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
}

.squares {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: all 0.25s ease;
  .square {
    width: 4px;
    height: 4px;
    display: inline-block;
    background: $gray-600;
    &:before,
    &:after {
      display: inline-block;
      width: 4px;
      height: 4px;
      content: "";
      background: $dark;
      margin-left: -18px;
      vertical-align: top;
    }
    &:after {
      background: $red;
      margin-right: -18px;
    }
  }
}

.vh-100 {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 250ms;
}

[data-aos] {
  visibility: hidden;
}
[data-aos].animated {
  visibility: visible;
}



.embed-responsive-100 {
  &:before {
    padding-top: 100vh;
    display: block;
    content: "";
  }
}
.embed-video-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.video-frame {
  border: 0;
  width: 100vw !important;
  height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh !important;
  min-width: 177.77vh !important; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 0 !important;
}

@keyframes kenburns {
  from {
    transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    transform: scale(1.15) translate3d(75px, -25px, 0);
  }
}

.swiper-slide-active {
  .kenburns {
    animation-fill-mode: forwards;
    animation-name: kenburns;
    animation-duration: 10s;
    animation-delay: 1s;
  }
}

.galleryslider {
  .swiper-button-prev,
  .swiper-button-next{
    top: 60%;
    &:hover {
      color: $red;
    }
    &:focus {
      outline: 0;
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      margin: 0 4px;
      border: 2px solid $gray-900;
      &.swiper-pagination-bullet-active {
        background: $gray-900;
      }
      &:focus {
        outline: 0;
      }
    }
    left: 0;
    right: 0;
    bottom: -35px;
  }

  @include media-breakpoint-up(lg) {
    .swiper-button-prev {
      left: -60px;
    }
    .swiper-button-next {
      right: -60px;
    }
  }
}

.tx-axnum-contact {
  margin-top: 30px;
  .card {
    background: none;
    border: 0;
    margin-bottom: 30px;
    .card-body {
      padding: 0 20px;
      @include media-breakpoint-down(sm) {
        padding: 20px 0;
      }
      .department {
        font-size: 0.75rem;
        .squares {
          margin-left: 14px;
          margin-top: 8px;
          display: inline-block;
          .square {
            width: 8px;
            height: 8px;
            &:before{
              width: 8px;
              height: 8px;
              margin-left:-18px;
            }
            &:after {
              width: 8px;
              height: 8px;
              margin-left: 18px;
            }
          }
        }
      }
      .card-text {
        font-size: .75rem;
      }
      .far {
        font-size: 1.5em;
      }
    }
  }
}

.table {
  font-weight: 400;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  thead {
     th {
        color: $white;
        background-color: $gray-900;
        border-bottom: 0;
        border-top: 0;
       font-weight: 300;
       font-size: 1.5625rem;
       font-family: "Open Sans Condensed";
    }
  }
  th,td {
    border-top: 0;
    padding: .5rem .75rem;
  }
  a {
    color: $red;
  }
}

.btn-dark {
  &:hover {
    background-color: $red;
    border-color: $red;
  }
}

.img-shadow {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}
.card-body-compact a {
  &:hover {
    .img-fluid {
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    }
  }
}
.img-shadow-product {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
}

.swiper-button-next,
.swiper-button-prev {
  color: $white;
  &:hover {
    color: $red;
  }
}
.swiper-button-next::after,
.swiper-button-prev::after {
  background: rgba(0, 0, 0, 0.3);
}
.swiper-button-prev::after {
  padding: 7px 18px 7px 13px;
}
.swiper-button-next::after {
  padding: 7px 13px 7px 18px;
}

.keyvisualslider {
  .swiper-button-next {
    right: 30px;
  }
  .swiper-button-prev{
    left: 30px;
  }
}
.bootstrap-select .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  box-shadow: none !important;
  border-color: $black;
  background-color: $white;
  background: $white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat right .75rem center;
  border-radius: 0;
  &:after {
    display: none;
  }
}
.bootstrap-select .dropdown-toggle {
  border-color: $gray-300;
}
.bootstrap-select.show .dropdown-toggle {
  outline: none !important;
  box-shadow: none !important;
  border-color: $black;
  background-color: $white;
  background: $white url("data:image/svg+xml;utf8,<svg viewBox='0 0 448 512' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z' fill='black'/></g></svg>") no-repeat right .75rem center;
}

.frame-boxed {
  background: $white;
  padding: 1rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}
