.card-header .card-link h4 {
  font-size: 1.5625rem;
  line-height: 34px;
  font-style: normal;
}
.card-header {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.17);
}
.card-header > a {
  position: relative;
  outline: 0;
}
.card-header > a > .fal {
  transition: all 0.25s ease;
}

.card-header> a[aria-expanded="true"] > .fal {
  transform: rotate(-180deg);
}
.card-link {
  display: block;
}

.card-link h4 {
  margin-right: 30px;
}

.answer {
  background: #fff;
  box-shadow: 0 0 7px rgba(0,0,0,.2);
}

.faq-categories {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
}