@media print {
  .nbar {
    &.hideme {
      top: 0;
      opacity: 1;
    }
    &.fixed-top {
      position: relative;
    }
  }
  [data-aos] {
    visibility: visible;
  }
}
