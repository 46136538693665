
header{
  .axnumevents_subheader {
    margin-bottom: 40px;
  }
}
.blog-compact .card-compact,
.newstips-compact .card-compact  {
  margin-bottom: 1.875rem;
}
.blog-compact .card-compact .image-box,
.newstips-compact .card-compact .image-box {
  text-align: right;
}
.blog-compact .card-body-compact,
.newstips-compact
.card-body-compact {
  line-height : 21px;
}
.tx-axnumnewstips-news {
  .newstips {
    a:hover {
      text-decoration: none;
    }
  }
}

.tx-axnumblog-blog {
  .blog {
    a:hover {
      text-decoration: none;
    }
  }
}


.card-body-compact h4 {
  margin-top: 3px;
  margin-bottom: 30px;
}
.blog-categories,
.newstips-categories {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.tx-axnumproducts-products {
  .small-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}


.tx-axnumevents-events {
  .events.card-deck {
    .card {
      overflow: hidden;
    }
  }
}
